import axios from "axios";
// Start Api
const ApiGetGeneralProviderData = (provider_id) => {
    try {
        return axios.get(`/api/providers/${provider_id}`)
    } catch (e) {
        return e.response.data
    }
};
const ApiGetUsersProviderData = (provider_id) => {
    try {
        return axios.get(`/api/users/providers/${provider_id}`)
    } catch (e) {
        return e.response.data
    }
};
const ApiGetRegionsProviderData = (provider_id) => {
    try {
        return axios.get(`/api/regions/providers/${provider_id}`)
    } catch (e) {
        return e.response.data
    }
};
const ApiSetOrganizationName = async (provider_id, new_name) => {
    try {
        return axios.post(`/api/providers/${provider_id}/name`, {name: new_name});
    } catch (e) {
        return e.response.data;
    }
}

// End Api
export const getGeneralProviderDataForAdmin = async (provider_id) => {
    const response = await ApiGetGeneralProviderData(provider_id);
    if (response?.data) return response.data;
    return null
    // func_callback(response);
};
export const getUsersProviderDataForAdmin = async (provider_id) => {
    const response = await ApiGetUsersProviderData(provider_id);
    if (response?.data) return response.data;
    return null
    // func_callback(response);
};
export const getRegionsProviderDataForAdmin = async (provider_id) => {
    const response = await ApiGetRegionsProviderData(provider_id);
    if (response?.data) return response.data;
    return null
    // func_callback(response);
};
export const setOrganizationName = async (send_data) => {
    let response;
    if (send_data?.provider_id && send_data?.name) response = await ApiSetOrganizationName(send_data.provider_id, send_data.name);

    if (response.status === 200 || response.status === 204) return 'done'
    else return response;
};
