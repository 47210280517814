<template>
    <CRow
            v-if="provider.id !== 0"
            class="w-100 justify-content-center">
        <CCard>
            <CCardHeader class="pb-0">
                <div class="card-header-action">
                    <h2>{{ provider.brand || '*Brand name is empty' }}</h2>
                </div>
            </CCardHeader>
            <CCardBody class="pt-2">
                <table class="table">
                    <tbody>
                    <tr>
                        <th class="border-top-0">Ім'я Організації</th>
                        <td class="border-top-0">{{ provider.name }}</td>
                    </tr>
                    <tr>
                        <th>Бренд</th>
                        <td>{{ provider.brand || '*' }}</td>
                    </tr>
                    <tr v-if="provider.ipn">
                        <th>ІПН</th>
                        <td>{{ provider.ipn || '*' }}</td>
                    </tr>
                    <tr v-else>
                        <th>ЄДРПОУ</th>
                        <td>{{ provider.edr || '*' }}</td>
                    </tr>
                    <tr>
                        <th>Web-site</th>
                        <td>{{ provider.website || '*' }}</td>
                    </tr>
                    <tr>
                        <th>Телефони підтримки</th>
                        <td>
                            <ul style="list-style-type: none" class="p-0">
                                <template v-if="provider?.technical_support_contacts">
                                    <li v-for="tel in provider.technical_support_contacts.split(',')" :key="tel">
                                        <a :href="'tel://'+tel">
                                            {{ tel }}
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Телефони бухгалтерії</th>
                        <td>
                            <ul style="list-style-type: none" class="p-0">
                                <template v-if="provider?.accounting_contacts">
                                    <li v-for="tel in provider.accounting_contacts.split(',')" :key="tel">
                                        <a :href="'tel://'+tel">
                                            {{ tel }}
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Email - Бухгалтерії</th>
                        <td>
                            <ul style="list-style-type: none" class="p-0">
                                <template v-if="provider?.accounting_emails">
                                    <li v-for="mail in provider.accounting_emails.split(',')" :key="mail">
                                        <a :href="'mailto:' + mail">
                                            {{ mail }}
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Email - Технічної підтримки</th>
                        <td>
                            <ul style="list-style-type: none" class="p-0">
                                <template v-if="provider?.technical_emails">
                                    <li v-for="mail in provider.technical_emails.split(',')" :key="mail">
                                        <a :href="'mailto:' + mail">
                                            {{ mail }}
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Надаємо магістральні канали</th>
                        <td>{{ provider.is_pipeline ? "Так" : "Ні" }}</td>
                    </tr>
                    <tr>
                        <th>Електронний документообіг</th>
                        <td>{{ provider.is_paper_less ? "Так" : "Ні" }}</td>
                    </tr>
                    <tr>
                        <th>Рейтинг провайдера</th>
                        <td>
                            <div class='ctrl'>
                                <div
                                        @click="changeRating(provider.rating -1 ,provider.id)"
                                        class='ctrl__button ctrl__button--decrement'
                                >&ndash;
                                </div>
                                <div class='ctrl__counter'>
                                    <input class='ctrl__counter-input' maxlength='10' type='text' value='0'>
                                    <div class='ctrl__counter-num'>{{ provider.rating }}</div>
                                </div>
                                <div
                                        @click="changeRating(provider.rating +1,provider.id)"
                                        class='ctrl__button ctrl__button--increment'
                                >+
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <CCol
                        v-if="regions !== null"
                >
                    <transition>
                        <CCard
                                class="border-0 pl-0 pt-lg-3"
                        >
                            <CCardHeader class="border-bottom-0 pl-0 pt-lg-3">
                                <h4>Карта присутності</h4>
                            </CCardHeader>
                            <CCardBody class="p-0 m-0">
                                <CRow style="max-width: 1000px;">
                                    <div v-for="(region, id) in regions" :key="id">
                                        <CButton
                                                color="success"
                                                class="m-1"
                                        >
                                            {{ region.region }}
                                        </CButton>
                                    </div>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </transition>
                </CCol>

                <hr>
                <CCardHeader class="border-bottom-0 mt-4 p-1 pl-0 pt-3 d-flex align-items-center">
                    <h4>Користувачі</h4>
                    <CButton
                            v-if="can('create_for_provider_user')"
                            size="sm"
                            color="secondary"
                            class="ml-2 p-1 d-flex align-items-center"
                            style="width: 25px; height: 25px"
                            @click="addProvideUser(true)"
                    >
                        <CIcon name="cil-plus" size="sm"/>
                    </CButton>
                </CCardHeader>
                <CCardBody
                        v-if="users.length !== 0"
                        class="p-0 m-0">
                    <provider-users-details-grid :users="users" :per-page="25"/>
                </CCardBody>
            </CCardBody>
            <CCardFooter>
                <CRow>
                    <CCol class="p-md-2 text-left" col="2">
                        <CButton size="sm" class="mt-1" color="secondary" @click="goBack">Назад</CButton>
                    </CCol>
                    <CCol class="p-md-2 text-right" col="10">
                            <CButton
                                    v-if="can('change_name_organization_for_provider')"
                                    size="sm"
                                    class="p-1 mt-1 text-justify"
                                    color="primary"
                                    @click="changeOrganizationName(provider.id, true)"
                            >
                                Змінити ім'я організації
                            </CButton>
                        <CButton
                                size="sm"
                                class="p-1 mt-1 ml-2 text-justify"
                                :color="getChangeColor(provider.is_verify)"
                                @click="approveVerification(provider.id)"
                                :disabled="isDisableVerifications(provider.is_verify)"
                        >
                            {{ provider.is_verify ? "Верифікований" : "Верифікувати" }}
                        </CButton>
                        <CButton
                                v-if="!provider.is_verify"
                                class="p-1 mt-1 ml-2 text-justify"
                                color="danger"
                                size="sm"
                                @click="refuseVerification(provider.id, true)"
                        >
                            Відмінити верифікацію
                        </CButton>
                        <un-block-provider
                                col="6"
                                size="sm"
                                class="mt-1 ml-2"
                                :provider="provider"/>
                    </CCol>
                </CRow>
            </CCardFooter>
        </CCard>
        <modal-any-information :show-any-information-modal="alertModal" @close="alertModalShow()">
            <template v-slot:body>
                <h4>Провайдер, ще не підтвердив емейл</h4>
            </template>
        </modal-any-information>

        <modal-create-provide-user :showCreateUser="showCreateUsers" :providerID="provider.id"
                                   :funcAddUser="addProviderUser" @updateValue="getUsers"
                                   @closing="addProvideUser()"/>
        <modal-refuse-verification :provider_id="provider_id" :show-refuse-verification-modal="modalRefuseVerifycation"
                                   @close="refuseVerification()"/>

        <modal-change-name-organization :provider_id="provider_id" :show-modal-change-org-name="changeNameOrganization"
                                        @close="changeOrganizationName()"/>
    </CRow>
</template>

<script>
import axios from "axios";
import ModalCreateProvideUser from "../Components/Modal/ModalCreateProvideUser";
import UnBlockProvider from "../Components/unBlockProvider";
import ProviderUsersDetailsGrid from "../Components/Grid/ProviderUsersDetailsGrid.vue";
import {
    getGeneralProviderDataForAdmin,
    getRegionsProviderDataForAdmin,
    getUsersProviderDataForAdmin
} from '../../api/getProviderDataForAdmin';
import {addProviderUser} from "../../api/createUsersForProvider";
import ModalRefuseVerification from "../Components/Modal/ModalRefuseVerification.vue";
import modalAnyInformation from "../Components/Modal/ModalAnyInformation.vue";
import modalChangeNameOrganization from "../Components/Modal/ModalChangeNameOrganization.vue";
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";

export default {
    name: "DetailsProviders",
    components: {
        ModalRefuseVerification,
        ModalCreateProvideUser,
        UnBlockProvider,
        ProviderUsersDetailsGrid,
        modalAnyInformation,
        modalChangeNameOrganization
    },
    data: () => {
        return {
            showCreateUsers: false,
            newProviderName: '',
            changeNameOrganization: false,
            modalRefuseVerifycation: false,
            provider_id: '',
            comment: '',
            provider: {
                rating: 0,
                id: 0,
                name: "",
                brand: "",
                edr: "",
                ipn: "",
                website: "",
                is_pipeline: false,
                is_paper_less: false,
                is_blocked: false,
                is_verify: false,
                accounting_contacts: [],
                technical_support_contacts: [],
                technical_emails: [],
                accounting_emails: [],
                users: [{
                    id: 0,
                    email: "",
                    first_name: "",
                    last_name: "",
                    phones: [],
                    notification: false,
                    role: "",
                }],
            },
            users: [{
                id: 0,
                email: "",
                first_name: "",
                last_name: "",
                phones: [],
                notification: false,
                role: "",
            }],
            regions: null,
            alertModal: false,
        }
    },
    methods: {
      can,
        addProviderUser,
        alertModalShow(show = false) {
            this.alertModal = show;
        },
        addProvideUser(show = false) {
            this.showCreateUsers = show;
            if (!show) this.updateData();
        },
        changeOrganizationName(provider_id = 0, show = false) {
            this.provider_id = provider_id
            this.changeNameOrganization = show;
            if (!show) this.updateData();
        },
        refuseVerification(provider_id = 0, show = false) {
            this.provider_id = provider_id
            this.modalRefuseVerifycation = show;
            if (!show) this.updateData()
        },
        updateData() {
            this.$store.dispatch('GET_PROVIDER');
            this.getProviderData();
        },
        getChangeColor(is_verify) {
            return is_verify === true ? 'secondary'
                : is_verify === false ? 'success'
                    : 'primary'
        },
        approveVerification(id) {
            let self = this;
            axios.post('/api/providers/' + id + '/verify')
                .then(function () {
                    self.updateData();
                })
                .catch(function () {
                    self.alertModalShow(true);
                })
        },
        changeRating(rating, id) {
            let self = this
            if (rating >= 1 && rating <= 10) {
                axios.post('/api/providers/' + id + '/rating/' + rating)
                    .then(function () {
                        self.updateData();
                    })
                    .catch(function () {
                    })
            }
        },
        isDisableVerifications(val) {
            return val === true ? true
                : val === false ? false : ''
        },
        goBack() {
          routerGoToName('BankAdminPortal');
            // this.$router.push({path: `/admins`});
        },
        async getProviderData() {
            const provider_id = this.$route.params.id
            this.provider = await getGeneralProviderDataForAdmin(provider_id)
        },
        async getUsers() {
            const provider_id = this.$route.params.id
            this.users = await getUsersProviderDataForAdmin(provider_id);
        },
        async getRegions() {
            const provider_id = this.$route.params.id
            this.regions = await getRegionsProviderDataForAdmin(provider_id);
        },
    },

    created() {
        this.getProviderData();
        this.getUsers();
        this.getRegions();
    }
}
</script>
