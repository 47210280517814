<template>
  <CModal
      title="Змінити ім'я організації"
      color="primary"
      sixe="lg"
      :show.sync="showModalChangeOrgName"
  >
    <template #body-wrapper>
      <CCardBody class="pt-4 pb-3">
        <CAlert v-if="myAlert.text.length" show :color="myAlert.color">{{ myAlert.text }}</CAlert>
        <div class="form-item" :class="{'errorInput': $v.new_organization_name.$error}">
          <CInput
              placeholder="Введіть нове ім'я організації"
              required
              v-model="new_organization_name"
              invalid-feedback="Введено некоректні дані"
              @change="$v.new_organization_name.$touch()"
              :show-labels="false"
              :class=" {
                      'error': $v.new_organization_name.$error
                    }"
          >
          </CInput>
          <small v-if="!$v.new_organization_name.required" class="form-text errorText w-100">Необхідно заповнити ім'я
            організації.</small>
          <small v-if="!$v.new_organization_name.maxLength || !$v.new_organization_name.minLength"
                 class="form-text errorText w-100">Макс./Мін. к-ть символів
            {{ $v.new_organization_name.$params.maxLength.max }}/{{
              $v.new_organization_name.$params.minLength.min
            }}.</small>
        </div>
      </CCardBody>
    </template>
    <template #footer>
      <CButton color="primary" @click="changeName()">Змінити</CButton>
    </template>
  </CModal>
</template>

<script>
import {minLength, maxLength, required} from "vuelidate/lib/validators";
import {setOrganizationName} from "../../../api/getProviderDataForAdmin";

export default {
  name: "ModalChangeNameOrganization",
  props: {
    showModalChangeOrgName: {
      required: true,
      default: false,
      type: Boolean
    },
    provider_id: {
      required: true,
      type: [String, Number],
    }
  },
  data() {
    return {
      new_organization_name: '',
      myAlert: {
        color: 'success',
        text: ''
      },
    }
  },
  validations: {
    new_organization_name: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255)
    }
  },
  methods: {
    async changeName() {
      this.$v.$reset();
      this.myAlert.text = '';
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const resp = await setOrganizationName({
        provider_id: this.$props.provider_id,
        name: this.new_organization_name
      });
      if (resp === 'done') {
        this.myAlert.color = 'success';
        this.myAlert.text = `Ім'я організації успішно змінено!`;
      } else {
        this.myAlert.color = 'danger'
        this.myAlert.text = resp || 'Сталась помилка! Дія не виконалась!';
      }

      setTimeout(() => {
        this.clear();
        this.$emit('close');
      }, 2000);
    },
    clear() {
      this.$v.$reset();
      this.myAlert = {
        color: 'success',
        text: ''
      };
      this.new_organization_name = '';
    }
  }
}
</script>
