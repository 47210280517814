import axios from "axios";

const APIAddProviderUser = (user) => {
    try {
        return axios.post(`/api/providers/${user.provider_id}`, user)
    } catch (e) {
        return e.response.data;
    }
}
export const addProviderUser = (user, callback_func) => {
    const response = APIAddProviderUser(user);

    response.then(val => val.status === 200 || val.status === 204 ? callback_func('added') : callback_func('failed'));
}
